export const locales = {
  'en-US': require('./en-US.json'),
  'zh-CN': require('./zh-CN.json'),
  'ja-JP': require('./ja-JP.json'),
};

export const LanguageMap = {
  'en-US': 'en_us',
  'zh-CN': 'zh_cn',
  'ja-JP': 'ja_jp',
  zh_Hk: 'zh_hk',
};
